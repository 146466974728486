import React from "react"
import SEO from "../components/seo"
import indexStlyles from "./index.module.scss"
import { Link } from 'gatsby';
import Grid from "@material-ui/core/Grid"
import Form from "../components/Form"
import home from "../images/home.jpg"
import WhyUs from "../components/whyUs"
import { textureOne } from "../components/svgTextureData"

function Index() {
  const textureOnePath = textureOne.map(x => x.d).join(" ")

  return (
    <>
      <SEO
        title="Marketing. Diseño. Desarrollo."
        image={`https://www.ppdc.mx${home}`}
        url=""
      />
      <div className={indexStlyles.hero}>
        <svg
          className={indexStlyles.texture}
          style={{
            fill: "#141a3a",
            stroke: "#000",
            strokeMiterlimit: 1,
            strokeWidth: 0.5,
          }}
          viewBox="0 0 1280 900"
        >
          <path className={indexStlyles.path} d={textureOnePath} />
        </svg>
        {/* <Spring config={{mass: 2,tension: 150, friction: 15, precision: 0.001, delay: 1000}}
                from={{ opacity: 0, transform: 'translate3d(0,0,1000px) scale(2) ' }}
                to={{ opacity: 1, transform: 'translate3d(0px,0,0) scale(1) ' }}>
                {props =>  */}
        <div className={indexStlyles.heroText}>
          <h2 className={indexStlyles.heroTitle}>
            Marketing. Diseño. Desarrollo.
          </h2>
          <Link
            className={indexStlyles.linkButton}
            paintDrip
            hex="#DAD4CC"
            to="/contact"
            title="contacto"
          >
            <button className={indexStlyles.ctaButton}>CONTACTO</button>
          </Link>
        </div>
        {/* }
            </Spring> */}
      </div>
      <WhyUs />
      <div className={indexStlyles.servicesArea}>
        <Grid container spacing={0} className={indexStlyles.servicesContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={indexStlyles.serviceContainer}
          >
            <Link
              title="desarrollo"
              swipe
              direction="up"
              exit="exit"
              entryOffset={150}
              to="/services/dev"
              className={indexStlyles.servicesLink}
            >
              <div
                className={[indexStlyles.serviceImage, indexStlyles.dev].join(
                  " "
                )}
              />
              <h2 className={indexStlyles.serviceTitle}>Desarrollo</h2>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={indexStlyles.serviceContainer}
          >
            <Link
              title="marketing"
              swipe
              direction="up"
              exit="exit"
              entryOffset={150}
              to="/services/marketing"
              className={indexStlyles.servicesLink}
            >
              <div
                className={[indexStlyles.serviceImage, indexStlyles.mkt].join(
                  " "
                )}
              />
              <h2 className={indexStlyles.serviceTitle}>Marketing Digital</h2>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={indexStlyles.serviceContainer}
          >
            <Link
              title="diseño"
              swipe
              direction="up"
              exit="exit"
              entryOffset={150}
              to="/services/design"
              className={indexStlyles.servicesLink}
            >
              <div
                className={[indexStlyles.serviceImage, indexStlyles.des].join(
                  " "
                )}
              />
              <h2 className={indexStlyles.serviceTitle}>Diseño</h2>
            </Link>
          </Grid>
        </Grid>
      </div>
      <div className={indexStlyles.toolsArea}>
        <Grid container spacing={3} className={indexStlyles.toolsGrid}>
          <Grid item xs={12} md={6} className={indexStlyles.toolsImage} />
          <Grid item xs={12} md={6} className={indexStlyles.toolsTextContainer}>
            <h3 className={indexStlyles.toolsTitle}>
              Tenemos las herramientas necesarias para tu organización…
            </h3>
            <p className={indexStlyles.toolsDescription}>
              Usamos las metodologías de innovación y desarrollo más
              vanguardistas en la industria. <strong>Design Sprint</strong> para
              innovar, <strong>Scrum</strong> para administrar proyectos.
              Conocemos los mejores y más actuales <strong>Frameworks</strong>{" "}
              de desarrollo como <strong>React</strong> y <strong>Vue</strong>{" "}
              para el font end; <strong>Django</strong>,{" "}
              <strong>Express</strong> y <strong>Flask</strong> para el back
              end. <br />
              Nuesto equipo se encuentra en un estado de sólida madurez en el
              uso de las princiales plataformas de marketing digital como{" "}
              <strong>Facebook</strong>, <strong>Google Ads</strong> y{" "}
              <strong>LinkedIn</strong>
            </p>
            <Link
              title="contact"
              className={indexStlyles.ctaTwoButton}
              paintDrip
              hex="#DAD4CC"
              to="/contact"
            >
              <button className={indexStlyles.ctaButton}>CONTACTO</button>
            </Link>
          </Grid>
        </Grid>
      </div>
      <div className={indexStlyles.contactArea}>
        <Grid container className={indexStlyles.contactGrid}>
          <Grid item xs={12} sm={12} md={6}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className={indexStlyles.formContainer}
          >
            <div className={indexStlyles.formBackground}>
              <Form />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Index
