import React from "react"

import whyUsStyles from "./whyUs.module.scss"

import Grid from "@material-ui/core/Grid"

import iconOne from "../images/whyus/ppdcicon1-01.svg"
import iconTwo from "../images/whyus/ppdcicon2-01.svg"
import iconThree from "../images/whyus/ppdcicon3-01.svg"
import iconFour from "../images/whyus/ppdcicon4-01.svg"
import iconFive from "../images/whyus/ppdcicon5-01.svg"
import iconSix from "../images/whyus/ppdcicon6-01.svg"
import iconSeven from "../images/whyus/ppdcicon7-01.svg"

import gif from "../images/whyus/gif.gif"

export default function WhyUs() {
  const objects = [
    {
      id: "ahsjs1",
      image: iconOne,
      title: "Estrategias Completas",
      description:
        "Conocemos a profundidad el funnel completo de marketing digital para superar los objetivos tu negocio",
    },
    {
      id: "ahsjs2",
      image: iconTwo,
      title: "Messenger Marketing Experts",
      description:
        "Tenemos la certificación Internacional para el desarrollo de ChatBots",
    },
    {
      id: "ahsjs3",
      image: iconThree,
      title: "Equipo Digital Multidisciplinario",
      description:
        "Entre el quipo sumamos más de 40 años de experiencia digital en diferentes industrias",
    },
    {
      id: "ahsjs7",
      image: iconSeven,
      title: "Información en tiempo real",
      description:
        "Trabajamos con herramientas que te permitirán conocer el desempeño y evolución de cualquier proyecto en el que trabajemos",
    },
    {
      id: "gifs",
      image: gif,
      title: "animación",
      description: "",
    },
    {
      id: "ahsjs5mpkvsvsk",
      image: iconFive,
      title: "Desarrollo a la medida",
      description:
        "Diseñamos a la medida de cada cliente y desarrollamos páginas web en menos de 3 semanas",
    },
    {
      id: "ahsjs6",
      image: iconSix,
      title: "Precio Justo",
      description:
        "Nos ajustamos a las necesidades y requerimientos de cada cliente; pagas sólo calidad y tiempo efectivo",
    },
    {
      id: "vacio",
      image: "",
      title: "",
      description: "",
    },
    {
      id: "ahsjs4",
      image: iconFour,
      title: "Decisiones basadas en datos",
      description: "Trabajamos por objetivos SMART y decidimos con KPI's",
    },
  ]

  return (
    <div className={whyUsStyles.container}>
      <div className={whyUsStyles.sectionTitleContainer}>
        <h2 className={whyUsStyles.sectionTitle}>
          ¿Por qué elegir a P&P Digital Consulting?
        </h2>
      </div>
      <Grid container>
        {objects.map(object =>
          object.id !== "gifs" && object.id !== "vacio" ? (
            <Grid
              item
              xs={12}
              md={4}
              key={object.id.toString()}
              className={whyUsStyles.gridItem}
            >
              <div className={whyUsStyles.cardContainer}>
                <div className={whyUsStyles.svgContainer}>
                  <img
                    className={whyUsStyles.svg}
                    src={object.image}
                    alt={object.title}
                  />
                </div>
                <div className={whyUsStyles.titleContainer}>
                  <h3 className={whyUsStyles.title}>{object.title}</h3>
                </div>
                <p className={whyUsStyles.description}>{object.description}</p>
              </div>
            </Grid>
          ) : object.id === "vacio" ? (
            <Grid className={whyUsStyles.emptyGrid} item md={4} />
          ) : (
            <Grid className={whyUsStyles.gifGrid} item md={4}>
              <div className={whyUsStyles.gifContainer}>
                <img
                  className={whyUsStyles.gif}
                  src={object.image}
                  alt={object.title}
                  key={object.id}
                />
              </div>
            </Grid>
          )
        )}
      </Grid>
    </div>
  )
}
