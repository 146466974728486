import React, { useState } from "react"
import FormStyles from "./form.module.scss"
import Grid from "@material-ui/core/Grid"

export default function Form() {
  function facebookPixel() {
    if(typeof window.fbq !== 'undefined'){
      window.fbq("track", "Lead")
    }
  }
  function gtag_report_conversion(url) {
    var callback = function() {
      if (typeof url !== "undefined") {
        window.location = url
      }
    }
    window.gtag("event", "conversion", {
      send_to: "AW-790590343/tCcnCKiJ_aEBEIfn_fgC",
      event_callback: callback,
    })
    return false
  }

  function handleSubmit(event) {
    event.preventDefault()
    const ipData = localStorage.getItem('ipData')
    const w = localStorage.getItem('window')
    const initialUrl = localStorage.getItem('initialUrl')
    if(!w){
      return
    }
    if (!event.target.checkValidity()) {
      setFormValidity(true)
      setAlert("Por favor verifica que tus datos sean correctos")
      return
    }
    setFormValidity(false)
    const data = {
        Placement: "PPDC WEB",
        "Nombre Completo": name,
        Email: email,
        Teléfono: phone,
        Comentarios: comments,
        ipData,
        w,
        initialUrl
    }
    gtag_report_conversion()
    facebookPixel()

    fetch("https://hook.integromat.com/s9q6ox9k7kroxy3kyax6ib542o9k4klw", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(data),
    }).then(response => {
      if (response.ok) {
        setApiResponse(true)
      } else setApiResponse(false)
    })
  }

  function focusOne() {
    setFocusedOne(true)
  }
  function focusTwo() {
    setFocusedTwo(true)
  }
  function focusThree() {
    setFocusedThree(true)
  }
  function focusFour() {
    setFocusedFour(true)
  }

  function blurOne() {
    if (name !== "") {
      return
    } else {
      setFocusedOne(false)
    }
  }
  function blurTwo() {
    if (email !== "") {
      return
    } else {
      setFocusedTwo(false)
    }
  }
  function blurThree() {
    if (phone !== "") {
      return
    } else {
      setFocusedThree(false)
    }
  }
  function blurFour() {
    if (comments !== "") {
      return
    } else {
      setFocusedFour(false)
    }
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [comments, setComments] = useState("")
  const [apiResponse, setApiResponse] = useState("")
  const [formValidity, setFormValidity] = useState("")
  const [focusedOne, setFocusedOne] = useState(false)
  const [focusedTwo, setFocusedTwo] = useState(false)
  const [focusedThree, setFocusedThree] = useState(false)
  const [focusedFour, setFocusedFour] = useState(false)
  const [alert, setAlert] = useState("")

  if (apiResponse === "") {
    return (
      <Grid item xs={12} sm={12} className={FormStyles.formContainer}>
        <h2 className={FormStyles.formTitle}>Contáctanos</h2>
        <form
          autoComplete="on"
          onSubmit={handleSubmit}
          noValidate
          className={FormStyles.form}
        >
          <Grid item xs={12} sm={12} className={FormStyles.formGroup}>
            <label className={FormStyles.label}>Nombre</label>
            <div className={FormStyles.inputContainer}>
              <input
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                name="name"
                className={[
                  FormStyles.input,
                  focusedOne ? FormStyles.activeInput : "",
                  formValidity ? FormStyles.displayError : "",
                ].join(" ")}
                onFocus={focusOne}
                onBlur={blurOne}
                placeholder="Nombre"
                autoComplete="name"
                required
              />
              <span className={FormStyles.span} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} className={FormStyles.formGroup}>
            <label className={FormStyles.label}>Email</label>
            <div className={FormStyles.inputContainer}>
              <input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                name="email"
                className={[
                  FormStyles.input,
                  focusedTwo ? FormStyles.activeInput : "",
                  formValidity ? FormStyles.displayError : "",
                ].join(" ")}
                onFocus={focusTwo}
                onBlur={blurTwo}
                placeholder="Correo"
                autoComplete="email"
                required
              />
              <span className={FormStyles.span} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} className={FormStyles.formGroup}>
            <label className={FormStyles.label}>Teléfono</label>
            <div className={FormStyles.inputContainer}>
              <input
                type="tel"
                value={phone}
                onChange={e => setPhone(e.target.value)}
                name="phone"
                className={[
                  FormStyles.input,
                  focusedThree ? FormStyles.activeInput : "",
                  formValidity ? FormStyles.displayError : "",
                ].join(" ")}
                onFocus={focusThree}
                onBlur={blurThree}
                placeholder="Teléfono"
                autoComplete="tel"
                required
              />
              <span className={FormStyles.span} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} className={FormStyles.formGroup}>
            <label className={FormStyles.label}>Comentarios (Opcional)</label>
            <div className={FormStyles.inputContainer}>
              <input
                type="text"
                value={comments}
                onChange={e => setComments(e.target.value)}
                name="comments"
                className={[
                  FormStyles.input,
                  focusedFour ? FormStyles.activeInput : "",
                  formValidity ? FormStyles.displayError : "",
                ].join(" ")}
                onFocus={focusFour}
                onBlur={blurFour}
                placeholder="Comentarios"
              />
              <span className={FormStyles.span} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} className={FormStyles.formGroup}>
            <div className={FormStyles.buttonContainer}>
              <p
                className={
                  formValidity ? FormStyles.alertShow : FormStyles.alertHidden
                }
              >
                {alert}
              </p>
              <button type="submit" className={FormStyles.submitButton}>
                Enviar
              </button>
            </div>
          </Grid>
        </form>
      </Grid>
    )
  } else if (apiResponse === true) {
    return (
      <div className={FormStyles.thankYou}>
        <Grid>
          <Grid xs={12} sm={12}>
            <h2 className={FormStyles.formTitle}>Gracias {name}!</h2>
            <div className={FormStyles.thankYouData}>
              <p>Alguien de nuestro equipo se pondra en contacto</p>
              <br />
              <p>Teléfono: {phone} </p>
              <p>Correo: {email}</p>
              <p>Comentarios: {comments}</p>
            </div>

            <button
              className={FormStyles.submitButton}
              onClick={() => setApiResponse("")}
            >
              Corregir datos
            </button>
          </Grid>
        </Grid>
      </div>
    )
  } else
    return (
      <div className={FormStyles.thankYou}>
        <Grid>
          <Grid>
            <h2 className={FormStyles.formTitle}>Disculpa {name}</h2>
            <p className={FormStyles.thankYouData}>
              Al parecer hubo un error con tu conexión! <br />
              Podrías ponerte en contacto con nosotros a través de{" "}
              <a href="https://m.me/PPDigitalConsulting">este link?</a>
              <br />
            </p>
          </Grid>
        </Grid>
      </div>
    )
}
